import { Button } from '@/components/Button';
import Text from '@/components/Text';
import { FeatureDisplay, FeatureKey, getFeatureDisplay, getFeatureLimit, listTierTemplates } from '@squaredup/tenants';
import { cva, VariantProps } from 'class-variance-authority';
import { useTenant } from 'queries/hooks/useTenant';
import { useTier } from 'queries/hooks/useTier';
import React from 'react';
import { IsTenantAdmin } from 'services/AccessControlService';
import { UpgradeMessageContainer } from './UpgradeMessageContainer';
import { useShowUpgradeModal } from './common';

const variants = cva(undefined, {
    variants: {
        content: {
            descriptive: '',
            subtle: ''
        },
        container: {
            modal: '',
            page: 'min-w-[450px] w-fit'
        }
    },
    defaultVariants: {
        container: 'modal',
        content: 'descriptive'
    }
});

const tiertemplates = listTierTemplates();

export interface LimitReachedBannerProps extends VariantProps<typeof variants> {
    featureKey: FeatureKey;
    currentUsage: number | undefined;
    className?: string;
    thresholdVerb?: string;
}

export const LimitReachedBanner: React.FC<LimitReachedBannerProps> = ({
    featureKey,
    currentUsage,
    className,
    thresholdVerb = 'reached',
    container,
    content
}) => {
    const { data: tenant } = useTenant();
    const { openUpgradeModal, upgradeModal } = useShowUpgradeModal(tenant);
    const showUpgradeButton = IsTenantAdmin(tenant) ?? false;
    // useTier handles validating the tier, and coercing items like dates from strings
    const { data: tier } = useTier();

    const feature = getFeatureDisplay(featureKey);
    if (!tier || feature === undefined || currentUsage === undefined) {
        return <></>;
    }

    const limit = getFeatureLimit(tier, featureKey);
    if ('isUnlimited' in limit) {
        return <></>;
    }

    if (limit.value > currentUsage) {
        return <></>;
    }

    const isSubtle = content === 'subtle';
    const isUpgradePending = tenant?.licenceData?.upgradePending ?? false;
    // Check if current tier is able to be further upgraded
    const tierCanBeUpgraded =
        (tiertemplates.find((t) => t.id === tier.tierTemplate)?.sortRank ?? 0) < tiertemplates.length - 1;

    return (
        <UpgradeMessageContainer
            data-testid='limit-reached-banner'
            className={variants({ container, content, className })}
            variant={isSubtle ? 'short' : 'normal'}
        >
            {isSubtle ? (
                <SubtleContent
                    feature={feature}
                    limit={limit}
                    userCanUpgrade={showUpgradeButton}
                    thresholdVerb={thresholdVerb}
                    openUpgradeModal={openUpgradeModal}
                    isUpgradePending={isUpgradePending}
                    organisationName={tenant?.displayName}
                    tierCanBeUpgraded={tierCanBeUpgraded}
                />
            ) : (
                <DescriptiveContent
                    feature={feature}
                    limit={limit}
                    userCanUpgrade={showUpgradeButton}
                    thresholdVerb={thresholdVerb}
                    openUpgradeModal={openUpgradeModal}
                    isUpgradePending={isUpgradePending}
                    organisationName={tenant?.displayName}
                    tierCanBeUpgraded={tierCanBeUpgraded}
                />
            )}
            {upgradeModal}
        </UpgradeMessageContainer>
    );
};

const getContactHref = (organisationName = '') =>
    `mailto:sales@squaredup.com?subject=Service%20Request&body=%0D%0A%0D%0A%E2%80%94-%0D%0AOrganisation%3A%20${organisationName}`;

type contentProps = {
    feature: FeatureDisplay;
    thresholdVerb: string;
    limit: { value: number };
    userCanUpgrade: boolean;
    openUpgradeModal: () => void;
    isUpgradePending: boolean;
    tierCanBeUpgraded: boolean;
    organisationName?: string;
};

const SubtleContent: React.FC<contentProps> = ({
    feature,
    thresholdVerb,
    limit,
    userCanUpgrade,
    openUpgradeModal,
    isUpgradePending,
    tierCanBeUpgraded,
    organisationName
}) => {
    return (
        <>
            <Text.H3 className='mb-1 mr-3 text-textPrimary'>
                {feature.singular ?? feature.displayName} limit {thresholdVerb} ({limit.value})
            </Text.H3>
            <Text.Body className='text-textSecondary'>
                {isUpgradePending ? (
                    <span>An existing upgrade is already in progress...</span>
                ) : userCanUpgrade ? (
                    <>
                        Upgrade to increase your limit.{' '}
                        {tierCanBeUpgraded ? (
                            <Button
                                variant='link'
                                onClick={openUpgradeModal}
                                data-testid={`upgrade-button-${feature.key}`}
                            >
                                Compare plans
                            </Button>
                        ) : (
                            <a
                                className='text-textLink hover:underline focus:underline'
                                href={getContactHref(organisationName)}
                            >
                                Contact sales
                            </a>
                        )}
                    </>
                ) : (
                    <>Please contact your administrator to increase your limit.</>
                )}
            </Text.Body>
        </>
    );
};

const DescriptiveContent: React.FC<contentProps> = ({
    feature,
    thresholdVerb,
    limit,
    userCanUpgrade,
    openUpgradeModal,
    isUpgradePending,
    tierCanBeUpgraded,
    organisationName
}) => {
    return (
        <>
            <div className='flex'>
                <div className='mr-9'>
                    <Text.H3 className='mb-2 text-textPrimary'>
                        {feature.singular ?? feature.displayName} limit {thresholdVerb} ({limit.value})
                    </Text.H3>
                    <Text.Body className='text-textPrimary'>
                        {userCanUpgrade
                            ? 'You can upgrade to increase your limit and'
                            : 'Please contact your administrator to increase your limit and'}{' '}
                        {feature.upgradeMessage}
                    </Text.Body>
                </div>
                <div className='ml-auto'>
                    {userCanUpgrade &&
                        (tierCanBeUpgraded ? (
                            <Button
                                variant={'upgrade'}
                                onClick={openUpgradeModal}
                                disabled={isUpgradePending}
                                data-testid={`upgrade-button-${feature.key}`}
                            >
                                {isUpgradePending ? 'Upgrading...' : 'Compare plans'}
                            </Button>
                        ) : (
                            <a href={getContactHref(organisationName)}>
                                <Button type='button' variant='upgrade'>
                                    Contact sales
                                </Button>
                            </a>
                        ))}
                </div>
            </div>
        </>
    );
};
