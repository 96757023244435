import { scopeLimitMaximum } from '@squaredup/constants';
import { buildQuery } from '@squaredup/graph';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import { useState } from 'react';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { getInitialObjectsSelection } from '../utilities/getInitialObjectsSelection';
import { FilterQueryParameters, getObjectFilters } from '../utilities/getObjectFilters';
import { getSavedScopeId } from '../utilities/getSavedScopeId';
import { getScopeQueryDetailValue } from '../utilities/getScopeQueryDetailValue';
import { useObjectFilterObjects } from './objectFilters/useObjectFilterObjects';
import { useObjectFilterProperties } from './objectFilters/useObjectFilterProperties';
import { useObjectFilterPropertyValues } from './objectFilters/useObjectFilterPropertyValues';
import { useObjectFilterScopeNodes } from './objectFilters/useObjectFilterScopeNodes';
import { useObjectFilterSources } from './objectFilters/useObjectFilterSources';
import { useObjectFilterTypes } from './objectFilters/useObjectFilterTypes';
import { useDataStreamMatchesToGremlin } from './useDataStreamMatchesToGremlin';
import { useScopes } from './useScopes';

export interface ProjectedObject {
    id: string;
    name: string;
    sourceType: string;
    type: string;
    configId: string;
}

export enum FilterType {
    sources = 'sources',
    types = 'types',
    properties = 'properties',
    query = 'query',
    scope = 'scope'
}

export type ObjectFilters = {
    selectedObjects: string[];
    query: string;
    sources: string[];
    types: string[];
    properties: Record<string, any[]>;
    scope: string;
};

/**
 * These options are used if we don't have any object filter or scope query values
 * Otherwise they will be defaulted to empty values.
 * Their primary purpose is populating the type filter value from the data stream filters
 */
export type FallbackDefaults = {
    type?: string;
};

export const useDataStreamObjectFilters = (objectFilters?: ObjectFilters, fallbackDefaults?: FallbackDefaults) => {
    const { config, setConfig } = useDatasetContext();
    const { workspace } = useDataStreamWorkspaceContext();
    const { variables = [] } = useDashboardContext();

    const [selectedObjects, setSelectedObjects] = useState<string[]>(
        objectFilters?.selectedObjects || getInitialObjectsSelection(config) || []
    );
    const [interactedObjects, setInteractedObjects] = useState<string[]>(selectedObjects);

    const [isDynamic, setIsDynamic] = useState(!getInitialObjectsSelection(config));

    const scopeQueryDetail = (config.scope as any)?.queryDetail;

    const [filterQuery, setFilterQuery] = useState(
        objectFilters?.query || getScopeQueryDetailValue(scopeQueryDetail, 'booleanQuery') || ''
    );
    const [filterSources, setFilterSources] = useState<string[]>(
        objectFilters?.sources || getScopeQueryDetailValue(scopeQueryDetail, 'plugins') || []
    );
    const hasSavedScope = Boolean(getSavedScopeId(config.scope, workspace));
    const [filterTypes, setFilterTypes] = useState<string[]>(
        objectFilters?.types || getScopeQueryDetailValue(scopeQueryDetail, 'types') || 
            ((!hasSavedScope && fallbackDefaults?.type) ? [fallbackDefaults!.type as string] : [])
    );
    const [filterProperties, setFilterProperties] = useState<Record<string, any[]>>(
        objectFilters?.properties || getScopeQueryDetailValue(scopeQueryDetail, 'properties') || {}
    );
    const [filterScope, setFilterScope] = useState<string>(
        objectFilters?.scope || getSavedScopeId(config.scope, workspace) || ''
    );

    const isConfigured = isDynamic || selectedObjects.length > 0;
    const hasDataStream = Boolean(config.dataStream);
    const hasVariables = Boolean(variables.length && config.variables?.length);

    const { isLoading: isLoadingDataStreamQuery, query: dataStreamQuery } = useDataStreamMatchesToGremlin(
        config.dataStream?.id
    );

    const { data: scopes, isLoading: isLoadingScopes } = useScopes();
    const filterScopeWorkspaceId = scopes?.find(({ id }) => id === filterScope)?.workspaceId;

    // Gets a gremlin query to filter the objects to those intersecting with the saved scope
    const { data: filterScopeObjectQuery, isFetching: isFetchingScopeObjectQuery } = useObjectFilterScopeNodes(
        filterScope,
        filterScopeWorkspaceId
    );

    const scopeBaseQuery = `${dataStreamQuery || ''}${
        filterScope && filterScopeObjectQuery ? filterScopeObjectQuery : ''
    }`;

    // We only want to enable other filter queries once we've loaded the dataStreamQuery
    // and the scope objects query
    const isFilterQueryReady =
        !hasDataStream || (dataStreamQuery !== undefined && (!filterScope || filterScopeObjectQuery !== undefined));

    const objectQueryParams = getObjectFilters(filterSources, filterTypes, filterProperties, filterQuery);

    // Gets all the sources for a data stream, filters them based on source IDs
    const { data: sources, isLoading: isLoadingSources } = useObjectFilterSources({
        dataStreamQuery: dataStreamQuery || '',
        queryParams: getObjectFilters([], [], {}, '')
    });

    // Gets all the types for a datastream on the filtered sources and properties
    const { data: types } = useObjectFilterTypes({
        scopeBaseQuery,
        queryParams: getObjectFilters(filterSources, [], filterProperties, ''),
        filterTypes,
        isFilterQueryReady
    });

    // Gets all properties for a datastream based on filtered sources, types, and properties
    const { data: properties, isLoading: isLoadingProperties } = useObjectFilterProperties({
        scopeBaseQuery,
        queryParams: getObjectFilters(filterSources, filterTypes, {}, ''),
        isFilterQueryReady
    });

    const { data: propertyValues, isFetching: isLoadingPropertyValues } = useObjectFilterPropertyValues({
        scopeBaseQuery,
        queryParams: getObjectFilters(filterSources, filterTypes, {}, ''),
        filterProperties
    });

    // Gets objects (object data) for a data stream based on filtered sources, types, properties, and pagination
    const {
        objects,
        isFetchingObjects: isFetching,
        isFetchingNextObjectsPage,
        isLoadingObjects,
        hasNextObjectsPage,
        count,
        fetchNextObjectsPage
    } = useObjectFilterObjects({
        scopeBaseQuery,
        queryParams: objectQueryParams,
        isFilterQueryReady
    });

    // Lookup pluginName and sourceName by sourceId
    const pluginLookup = new Map(
        sources
            ?.filter((source) => Boolean(source.plugin?.pluginId))
            ?.map((source) => [
                source.id as string,
                {
                    pluginName: source.plugin?.name ?? (source.displayName as string),
                    sourceName: source.displayName as string
                }
            ])
    );

    const handleFixedScope = (selected: string[]) => {
        const hasSelected = selected.length > 0;
        const scopeContents = {
            ids: selected,
            ...objectQueryParams
        };

        const scope = buildQuery(scopeContents, '');

        setSelectedObjects(selected);
        setInteractedObjects((prevSelected) => [...new Set([...prevSelected, ...selected])]);

        setConfig((currentConfig) => ({
            ...currentConfig,
            // Clear scope if there are no nodes selected
            ...(hasSelected
                ? {
                      scope: {
                          query: scope.gremlinQuery,
                          bindings: scope.bindings,
                          queryDetail: scopeContents
                      }
                  }
                : {
                      scope: undefined
                  })
        }));
    };

    const handleSaveScope = (scopeId: string, scopeWorkspaceId: string) => {
        // Set the selection mode to dynamic and clear all filters except the new scope
        setIsDynamic(true);
        setFilterScope(scopeId);
        setFilterSources([]);
        setFilterTypes([]);
        setFilterProperties({});

        setConfig((currentConfig) => ({
            ...currentConfig,
            scope: {
                workspace: scopeWorkspaceId ?? workspace,
                scope: scopeId
            }
        }));
    };

    const resetFilters = () => {
        setFilterScope('');
        setFilterSources([]);
        setFilterTypes([]);
        setFilterProperties({});
    };

    // Use the filter state to generate a dynamic scope and add it the tile config. Because we allow the user to select
    // a scope to filter the list of objects, we also need to include that criteria (if a filterScope is present).
    const handleDynamicScope = (newFilterState?: FilterQueryParameters) => {
        // If there is a scope filter we can safely assume we are changing the scope type to dynamic while a
        // scope filter is being used (we don't allow a scope filter when other filters are being used).
        if (filterScope) {
            return setConfig((currentConfig) => ({
                ...currentConfig,
                scope: {
                    workspace: filterScopeWorkspaceId ?? workspace,
                    scope: filterScope
                }
            }));
        }

        const scopeContents = newFilterState ?? objectQueryParams;

        const dynamicScope = buildQuery(scopeContents, dataStreamQuery || '', { limit: true });

        setConfig((currentConfig) => ({
            ...currentConfig,
            variables: undefined,
            scope: {
                query: dynamicScope.gremlinQuery,
                bindings: dynamicScope.bindings,
                queryDetail: scopeContents
            }
        }));

        // Reset selected objects
        setSelectedObjects([]);
    };

    /**
     * Function that takes the filterType enum, a new value, and the state setter and setsState - updating the
     * config if dynamic. The function also resets the page to 0 and updates the scope immediately if isDynamic is true
     * @param filterType The filter type enum, this is used to decide where to inject a new value in dynamic scopes
     * @param filterValue The filter value can either be a callback or a value
     * @param filterSetState The filter's setState dispatch function
     */
    const handleSetFilterState = (
        filterType: FilterType,
        filterValue: any,
        filterSetState: React.Dispatch<React.SetStateAction<any>>,
        setAsDynamic?: boolean
    ) => {
        filterSetState(filterValue);

        const variableScopes = variables.map((v) => v.scopeId);
        const scopeIsVariable = variableScopes.includes(filterValue);
        if (filterType === FilterType.scope && scopeIsVariable) {
            setConfig((currentConfig) => ({
                ...currentConfig,
                scope: scopeIsVariable ? { scope: filterValue, workspace } : currentConfig.scope,
                variables: scopeIsVariable ? variables.map((v) => v.id) : undefined
            }));

            return;
        }

        // If we're editing a dynamic scope any filter change should be reflected in the config
        if (isDynamic || setAsDynamic) {
            setIsDynamic(true);
            // If we're filtering by scope just use the workspace scope as the scope config (if not removing the filter)
            if (filterType === FilterType.scope) {
                if (!filterValue) {
                    const dynamicScope = buildQuery(objectQueryParams, dataStreamQuery || '', { limit: true });

                    return setConfig((currentConfig) => ({
                        ...currentConfig,
                        variables: undefined,
                        scope: {
                            query: dynamicScope.gremlinQuery,
                            bindings: dynamicScope.bindings,
                            queryDetail: objectQueryParams
                        }
                    }));
                }

                setConfig((currentConfig) => {
                    return {
                        ...currentConfig,
                        variables: undefined,
                        scope: {
                            workspace: filterScopeWorkspaceId ?? workspace,
                            scope: filterValue
                        }
                    };
                });
            } else {
                handleDynamicScope(
                    getObjectFilters(
                        filterType === FilterType.sources ? filterValue : filterSources,
                        filterType === FilterType.types ? filterValue : filterTypes,
                        filterType === FilterType.properties ? filterValue : filterProperties,
                        filterType === FilterType.query ? filterValue : filterQuery
                    )
                );
            }
        } else if (filterType === FilterType.scope) {
            setConfig((currentConfig) => ({
                ...currentConfig,
                variables: undefined
            }));
        }
    };

    const scopeFilterApplied = Boolean(filterScope);
    const nonScopefiltersApplied = Boolean(
        filterSources.length ||
            filterTypes.length ||
            Object.values(filterProperties).some((filterPropertyValues) => Boolean(filterPropertyValues.length))
    );

    const filtersDisabled = hasDataStream ? isDynamic && scopeFilterApplied : false;
    const scopesDisabled = hasDataStream ? isDynamic && nonScopefiltersApplied : false;
    const dynamicToggleDisabled = !isDynamic && scopeFilterApplied && nonScopefiltersApplied;

    // We only want to be in fetching state if we are fetching new data
    const isFetchingObjects = isFetchingScopeObjectQuery || isFetching;

    const isLoadingFilters = isLoadingDataStreamQuery || isLoadingScopes || isLoadingSources;

    const selectedObjectsCount = Math.min(selectedObjects.length, scopeLimitMaximum);
    const dynamicObjectsCount = Math.min(count ?? 0, scopeLimitMaximum);

    const selectedAllObjects = variables.every((v) => v.selectedAll);
    const variableObjects = variables.flatMap((v) => v.selectedObjects).map((v) => v.id);

    const isFiltered = Boolean(
        filterScope || 
        filterTypes.length ||
        filterSources.length ||
        Object.values(filterProperties).flat().length
    );

    return {
        isConfigured,
        objects,
        count,
        selectedObjectsCount,
        dynamicObjectsCount,
        pluginLookup,
        isFiltered,
        isFetchingObjects,
        isLoadingObjects,
        isLoadingFilters,
        isLoadingProperties,
        isLoadingPropertyValues,
        hasNextObjectsPage,
        isFetchingNextObjectsPage,
        dataStreamQuery,
        scopes,
        types,
        sources,
        properties,
        propertyValues,
        isDynamic,
        selectedObjects: hasVariables ? variableObjects : selectedObjects,
        selectedAllObjects: hasVariables && selectedAllObjects,
        nonScopefiltersApplied,
        filtersDisabled,
        scopesDisabled,
        dynamicToggleDisabled,
        filterQuery,
        filterScope,
        filterProperties,
        filterSources,
        filterTypes,
        interactedObjects,
        hasVariables,
        setInteractedObjects,
        setIsDynamic,
        setFilterQuery,
        setFilterScope,
        setFilterProperties,
        setFilterSources,
        setFilterTypes,
        setSelectedObjects,
        handleSaveScope,
        handleDynamicScope,
        handleFixedScope,
        handleSetFilterState,
        resetFilters,
        fetchNextObjectsPage
    };
};
