import { cn } from '@/lib/cn';
import { cva, VariantProps } from 'class-variance-authority';
import clsx from 'clsx';
import Tooltip from 'components/tooltip/Tooltip';
import { ButtonHTMLAttributes, forwardRef, MouseEvent, ReactNode } from 'react';

export const buttonVariants = cva(
    'inline-flex items-center select-none focus:ring-0 focus:outline-none disabled:pointer-events-none aria-disabled:pointer-events-none',
    {
        variants: {
            variant: {
                destructive:
                    'py-input px-md font-semibold rounded leading-input bg-destructiveButtonBackground text-primaryButtonText whitespace-nowrap hover:bg-destructiveButtonBackgroundHover focus-visible:bg-destructiveButtonBackgroundHover disabled:bg-destructiveButtonBackgroundDisabled disabled:text-primaryButtonTextDisabled',
                primary:
                    'py-input px-md font-semibold rounded leading-input bg-primaryButtonBackground text-primaryButtonText whitespace-nowrap hover:bg-primaryButtonBackgroundHover focus-visible:bg-primaryButtonBackgroundHover disabled:bg-primaryButtonBackgroundDisabled disabled:text-primaryButtonTextDisabled',
                secondary:
                    'py-input px-md font-semibold rounded leading-input bg-secondaryButtonBackground text-secondaryButtonText whitespace-nowrap ring-secondaryButtonOutline ring-inset ring-1 hover:bg-secondaryButtonBackgroundHover hover:ring-secondaryButtonOutlineHover focus-visible:bg-secondaryButtonBackgroundHover focus-visible:ring-secondaryButtonOutlineHover disabled:bg-secondaryButtonBackgroundDisabled disabled:text-secondaryButtonTextDisabled',
                tertiary:
                    'text-tertiaryButton tracking-body hover:text-tertiaryButtonHover focus-visible:text-tertiaryButtonHover whitespace-nowrap disabled:text-tertiaryButtonDisabled',
                link: 'text-textLink tracking-body hover:underline focus-visible:underline disabled:text-textLinkDisabled',
                choice: 'py-input px-md rounded leading-input bg-componentBackgroundPrimary text-textPrimary whitespace-nowrap ring-tileOutline ring-inset ring-1 hover:bg-secondaryButtonBackgroundHover hover:ring-secondaryButtonOutlineHover focus-visible:secondaryButtonBackgroundHover focus-visible:ring-secondaryButtonOutlineHover disabled:bg-componentBackgroundPrimary disabled:text-textDisabled disabled:ring-tileOutline aria-disabled:bg-componentBackgroundPrimary aria-disabled:text-textDisabled aria-disabled:ring-tileOutline',
                upgrade:
                    'py-input px-md font-semibold rounded leading-input bg-upgradeButtonBackgroundPrimary text-upgradeButtonTextPrimary whitespace-nowrap hover:bg-upgradeButtonBackgroundHover focus-visible:bg-upgradeButtonBackgroundHover disabled:bg-upgradeButtonBackgroundDisabled disabled:text-upgradeButtonTextDisabled'
            }
        },
        defaultVariants: {
            variant: 'primary'
        }
    }
);

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
    icon?: ReactNode;
    href?: string;
    onClick?: () => void;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ href, title, icon, variant, className, children, type, disabled, onClick, ...other }, ref) => {
        let buttonType = type;

        if (buttonType == null) {
            buttonType = onClick ? 'button' : 'submit';
        }

        const clickHandler = (e: MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            if (onClick) {
                onClick();
            }

            // Blur in case this button remains visible and nothing else
            // takes focus, which without this leaves the button with it's
            // focused styles until the user clicks elsewhere
            e.currentTarget?.blur();

            if (href) {
                window.open(href, '_blank');
            }
        };

        const button = (
            <button
                ref={ref}
                aria-label={title}
                onClick={clickHandler}
                type={buttonType}
                className={cn([buttonVariants({ variant }), className])}
                disabled={disabled}
                {...other}
            >
                {icon && <span className={clsx(children && 'mr-3 inline-flex items-center')}>{icon}</span>}
                {children}
            </button>
        );

        if (title) {
            return (
                <Tooltip title={title} disabled={disabled}>
                    {button}
                </Tooltip>
            );
        }

        return button;
    }
);

export default Button;
