import { cn } from '@/lib/cn';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'components/tooltip/Tooltip';
import { FC, ReactNode } from 'react';

export interface ToggleStyleWrapperProps {
    name: string;
    label: string;
    description?: string;
    disabled?: boolean;
    help?: string;
    icon?: IconProp;
    title?: string;
    labelClasses?: string;
    wrapperClassName?: string;
    testId?: string;
    upgradePill?: ReactNode;
}

export const ToggleStyleWrapper: FC<ToggleStyleWrapperProps> = ({
    name,
    label,
    description,
    disabled,
    help,
    icon,
    title,
    labelClasses,
    wrapperClassName,
    children,
    upgradePill
}) => {
    return (
        <div
            className={cn(
                'relative flex items-center mt-6 leading-input rounded-input py-toggleWrapper px-md first:mt-0 bg-componentBackgroundSecondary',
                wrapperClassName
            )}
            title={title}
        >
            <div className='flex items-center'>
                {icon && (
                    <div className={cn('mr-2', disabled && 'opacity-50')}>
                        <FontAwesomeIcon fixedWidth className='' icon={icon} />
                    </div>
                )}
                <div className='mr-8'>
                    <label
                        className={cn('flex items-center select-none', !disabled && 'cursor-pointer', labelClasses)}
                        htmlFor={name}
                    >
                        <span className={cn(disabled && 'opacity-50')}>{label}</span>
                        {help && (
                            <span className='px-2 cursor-pointer text-textSecondary'>
                                <Tooltip title={help}>
                                    <FontAwesomeIcon icon={faCircleQuestion} />
                                </Tooltip>
                            </span>
                        )}
                        {upgradePill}
                    </label>
                    {description && <div className='text-sm mt-xxxs text-textSecondary'>{description}</div>}
                </div>
            </div>
            <div className={cn('ml-auto inline-flex items-center shrink-0', disabled && 'opacity-50')}>{children}</div>
        </div>
    );
};
