import LoadingSpinner from 'components/LoadingSpinner';
import { useDashboardContext } from 'contexts/DashboardContext';
import stringify from 'fast-json-stable-stringify';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { HealthStateConfig } from './Config';
import { StaticImage } from './StaticImage';
import { getHealthData } from './healthUtils';
import { extractIds, parseInlineSVG } from './svgUtils';

interface SVGImageProps {
    src: string;
    tileId: string;
    isPreview: boolean;
    healthConfig: HealthStateConfig;
}

export const SVGImage: React.FC<SVGImageProps> = ({ src, tileId, isPreview, healthConfig }) => {
    const {
        dashboard: { workspaceId }
    } = useDashboardContext();

    const { sourceIds, ids } = useMemo(
        () => extractIds(src),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [src, isPreview, stringify(healthConfig)]
    );

    const { data: healthStates, isLoading } = useQuery(
        ['liveHealthStates', workspaceId, ...sourceIds, ...ids],
        async () => getHealthData(workspaceId, sourceIds, ids),
        {
            enabled: Boolean(workspaceId && (sourceIds.length > 0 || ids.length > 0)),
            cacheTime: 0, // do not cache
            staleTime: 0, // always treat as stale
            refetchInterval: 30_000 // auto refresh health every 30 seconds
        }
    );

    const svgElement = useMemo(() => {
        try {
            if (!healthStates) {
                return null;
            }
            return parseInlineSVG({
                src,
                tileId,
                // Add padding and overflow visible to allow
                // glow to show correctly on elements against the edge of the SVG
                className: 'm-auto max-w-full p-2 overflow-visible h-full w-full fill-transparent',
                healthConfig,
                healthStates,
                isPreview
            });
        } catch (e) {
            return null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src, tileId, isPreview, healthStates, stringify(healthConfig)]);

    if (isLoading) {
        return (
            <div className='flex h-full items-center justify-center'>
                <LoadingSpinner />
            </div>
        );
    }

    // If parsing the SVG src fails, fallback to a static <img />
    if (!svgElement) {
        return <StaticImage src={src} />;
    }

    return <>{svgElement}</>;
};
