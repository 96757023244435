import { Button } from '@/components/Button';
import Text from '@/components/Text';
import trackEvent from 'lib/analytics';
import { useState } from 'react';
import { ProjectedTenantResponse } from 'services/TenantService';
import { PlanUpgradeModal } from './PlanUpgradeModal';

type UserPlanProps = {
    tenant?: ProjectedTenantResponse;
    contactHref: string;
    tierCanBeUpgraded: boolean;
};

export const UpgradeButton = ({ contactHref, tenant, tierCanBeUpgraded }: UserPlanProps) => {
    const [purchaseSucceeded, setPurchaseSucceeded] = useState(tenant?.licenceData?.upgradePending ?? false);
    const [isUpgradeModalOpen, setUpgradeModalOpen] = useState(false);

    const handleButtonClick = () => {
        if (tenant == null) {
            return undefined;
        }

        setUpgradeModalOpen(true);
        trackEvent('Upgrade Modal Opened', { tenantId: tenant.id });
    };

    if (purchaseSucceeded) {
        return (
            <Text.Body className='mb-4 text-statusHealthyPrimary'>
                Thank you - your purchase was successful and your new plan will be active shortly.
            </Text.Body>
        );
    }

    return (
        <>
            {tenant && isUpgradeModalOpen && (
                <PlanUpgradeModal
                    onClose={() => setUpgradeModalOpen(false)}
                    onPurchase={() => setPurchaseSucceeded(true)}
                    tenant={tenant}
                />
            )}
            {tierCanBeUpgraded ? (
                <Button variant={'upgrade'} onClick={handleButtonClick} data-testid='upgradePlanButton'>
                    Compare plans
                </Button>
            ) : (
                <a href={contactHref}>
                    <Button type='button' variant='upgrade' data-testid='upgradePlanButton'>
                        Contact sales
                    </Button>
                </a>
            )}
        </>
    );
};
