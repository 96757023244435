import { DataStreamGremlinScope, isPresetOf } from '@squaredup/data-streams';
import { SqUpPluginId, type Serialised } from '@squaredup/ids';
import type { UIConfig } from '@squaredup/utilities';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import type { ProjectedDataStreamDefinitionEntity } from 'dynamo-wrapper';
import { groupBy } from 'lodash';
import { useDataSourceConfig } from 'pages/datasources/components/useDataSourceConfig';
import { useScopeContentToObjects } from 'queries/hooks/useScopeContentToObjects';
import { useQuery } from 'react-query';
import { useDataStreamDefinitionsForWorkspace } from 'services/DataStreamDefinitionService';
import { Get as getPlugin } from 'services/PluginService';
import { useDatasetContext } from '../../contexts/DatasetContext';

const getDataStreamFormTemplate = (
    dataStream: Serialised<ProjectedDataStreamDefinitionEntity> | undefined,
    allDataStreams: Serialised<ProjectedDataStreamDefinitionEntity>[]
): Serialised<UIConfig[]> | undefined => {
    if (dataStream == null) {
        return dataStream;
    }

    if (dataStream.template != null) {
        return dataStream.template;
    }

    if (dataStream.definition.presetOf != null) {
        return allDataStreams.find((parent) => isPresetOf(parent, dataStream))?.template;
    }

    return undefined;
};

/**
 * Resolves the current data stream id to a data stream instance (the source data stream if it is a configured stream),
 * and gathers the formDefaults (if it is a configured data stream)
 * @returns react hook form instance, the resolved form fields, and the loading state
 */
export const useDataStreamTemplate = () => {
    const { config } = useDatasetContext();
    const { workspace } = useDataStreamWorkspaceContext();

    const dataStreamId = config.dataStream?.id;

    const { data: streamDefinitions = [], isLoading: isLoadingStreamDefinitions } =
        useDataStreamDefinitionsForWorkspace();

    const dataStream = streamDefinitions.find((ds) => ds.id === dataStreamId);
    const isConfigrableDataStreamPreset = dataStream?.definition?.presetOf != null;
    const formTemplate = getDataStreamFormTemplate(dataStream, streamDefinitions);
    const parentDataStream = dataStream
        ? streamDefinitions.find((parent) => isPresetOf(parent, dataStream))
        : undefined;

    const pluginId = dataStream?.pluginId;

    const { data: objects, isLoading: isLoadingObjects } = useScopeContentToObjects(
        config?.scope && 'query' in config.scope ? (config.scope as DataStreamGremlinScope) : undefined,
        workspace
    );

    // For this step, we're just picking the first configId available. When multiple data sources are selected this will
    // mean the template is shown in the context of one, but requests will be made for all in the backend
    // TODO: Should we be limiting some streams to a single data source, e.g. webapi request
    const objectsByConfigId = groupBy(objects, (o) => o.__configId);
    const pluginConfigId = Object.keys(objectsByConfigId)[0];

    const { data: pluginConfig, isLoading: isLoadingPluginConfig } = useDataSourceConfig(
        pluginConfigId,
        !isLoadingObjects && Boolean(pluginId) && pluginId !== SqUpPluginId.value
    );

    const { data: plugin, isLoading: isLoadingPlugin } = useQuery(['plugin', pluginId], () => getPlugin(pluginId!), {
        enabled: Boolean(pluginId)
    });

    const isLoading = isLoadingPluginConfig || isLoadingPlugin || isLoadingStreamDefinitions;

    return {
        dataStream,
        parentDataStream,
        pluginConfig,
        formTemplate,
        formDefaultValues: isConfigrableDataStreamPreset ? dataStream.definition.dataSourceConfig : undefined,
        plugin,
        isLoading,
        streamDefinitions
    };
};
