import { HealthState } from '@squaredup/monitoring';
import { isFeatureEnabled } from '@squaredup/tenants';
import LoadingSpinner from 'components/LoadingSpinner';
import type { ViewOption } from 'dynamo-wrapper';
import { useDashboardsWithHealth } from 'queries/hooks/useDashboardsWithHealthStates';
import { useMonitorsWithHealth } from 'queries/hooks/useMonitorsForDashboards';
import { useTier } from 'queries/hooks/useTier';
import { useWorkspacesWithHealthRollup } from 'queries/hooks/useWorkspacesWithHealthRollup';
import { useMemo } from 'react';
import { useDataCount } from '../utils/useDataCount';
import { GlobalViewEmptyMessage } from './GlobalViewEmptyMessage';
import { StatusBlockDataItem } from './blocks/StatusBlock';
import { StatusBlocks } from './blocks/StatusBlocks';

export type SortValue = 'state' | 'name';

export type StatusRequestType = 'space' | 'dash' | 'monitor';

export interface StatusOverviewProps {
    sort: {
        value: SortValue;
        direction: 'asc' | 'desc';
    };
    stateFilters: HealthState[];
    type: StatusRequestType;
    openCreateWorkspace?: () => void;
    fillBlocksGrid?: boolean;
    tags?: string[];
    workspaceId?: string;
    maxColumns?: number;
    workspaceTypes?: string[];
    viewOptions?: ViewOption[];
}

export default function StatusOverview({
    openCreateWorkspace,
    maxColumns = 3,
    fillBlocksGrid,
    workspaceId: currentWorkspaceId,
    tags,
    workspaceTypes,
    viewOptions,
    ...requestParams
}: StatusOverviewProps) {
    const { data: tier } = useTier();

    const { data: workspacesData, isLoading: workspacesLoading } = useWorkspacesWithHealthRollup({
        select: (data): (StatusBlockDataItem & { workspaceId: string; type: string; tags: string[] })[] =>
            data.map(({ state, displayName: name, id, data: { properties } }) => ({
                state,
                name,
                id,
                link: `/workspace/${id}`,
                workspaceId: id,
                type: properties?.type ?? '',
                tags: properties?.tags ?? []
            }))
    });

    const { data: dashboardsData, isLoading: loadingDashboards } = useDashboardsWithHealth({
        enabled: requestParams.type === 'dash',
        select: (data): (StatusBlockDataItem & { workspaceId: string })[] =>
            data.map(({ state, displayName: name, id, workspaceId }) => ({
                state,
                name,
                id,
                link: `/dashboard/${id}`,
                workspaceId
            }))
    });

    const { data: monitorsData, isLoading: loadingMonitors } = useMonitorsWithHealth({
        enabled: requestParams.type === 'monitor',
        select: (data): (StatusBlockDataItem & { workspaceId: string })[] =>
            data.map(({ state, tileId, workspaceId, dashId, tileName, dashName }) => ({
                state,
                name: `Tile '${tileName}' of dashboard '${dashName}'`,
                id: `${dashId}/${tileId}`,
                link: `/dashboard/${dashId}#${tileId}`,
                workspaceId
            }))
    });

    const isLoadingData = workspacesLoading || loadingDashboards || loadingMonitors;

    const data = useMemo(() => {
        let items: (StatusBlockDataItem & { type?: string; tags?: string[]; workspaceId: string })[] = [];

        if (requestParams.type === 'space') {
            items = workspacesData ?? [];
        } else if (requestParams.type === 'dash') {
            items = dashboardsData ?? [];
        } else {
            items = monitorsData ?? [];
        }

        // filter state
        if (requestParams.stateFilters.length) {
            items = items.filter(({ state }) => requestParams.stateFilters.includes(state));
        }

        let filteredWorkspaces = workspacesData;
        // filter tags
        if (tags?.length) {
            filteredWorkspaces = filteredWorkspaces?.filter((space) => tags.some((tag) => space.tags.includes(tag)));
        }

        // filter type
        if (workspaceTypes?.length) {
            filteredWorkspaces = filteredWorkspaces?.filter(
                (space) =>
                    (space.type === '' && workspaceTypes.includes('[Not set]')) || workspaceTypes.includes(space.type)
            );
        }

        items = items.filter(({ workspaceId }) => filteredWorkspaces?.some(({ id }) => workspaceId === id));

        // Sort the data
        if (requestParams.sort.value === 'state') {
            const valueMap = {
                unknown: requestParams.sort.direction === 'asc' ? 4 : 0,
                error: 3,
                warning: 2,
                success: 1
            } as Record<string, number>;

            // Fallback to alphabetical when the states are equal
            items = items.sort((a, b) =>
                requestParams.sort.direction === 'asc'
                    ? valueMap[a.state] - valueMap[b.state] || a.name.localeCompare(b.name)
                    : valueMap[b.state] - valueMap[a.state] || a.name.localeCompare(b.name)
            );
        } else {
            items = items.sort((a, b) =>
                requestParams.sort.direction === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
            );
        }

        return items;
    }, [
        dashboardsData,
        monitorsData,
        requestParams.sort.direction,
        requestParams.sort.value,
        requestParams.stateFilters,
        requestParams.type,
        tags,
        workspaceTypes,
        workspacesData
    ]);

    const { workspaceCount, dashboardCount, monitorCount } = useDataCount();

    const isLoading = loadingDashboards || isLoadingData;

    const showAddWorkspaceButton =
        !isLoading && data && data.length < 3 && workspaceCount < 3 && requestParams.type === 'space';

    const isWorkspacesAvailable = (tier && isFeatureEnabled(tier, 'workspaces')) || workspaceCount === 0;

    return (
        <div className='flex space-x-3 grow'>
            <div className='w-full grow'>
                {!isLoading && data && (
                    <StatusBlocks
                        data={data}
                        type={requestParams.type}
                        columns={maxColumns}
                        fillGrid={fillBlocksGrid}
                        viewOptions={viewOptions}
                        showAddWorkspace={Boolean(showAddWorkspaceButton)}
                        onAddWorkspaceClick={openCreateWorkspace}
                        isWorkspaceFeatureEnabled={isWorkspacesAvailable}
                        noBlocksMessage={
                            /** No data found & we aren't showing an add workspace button */
                            !isLoading &&
                            data &&
                            data.length === 0 &&
                            (workspaceCount > 2 || requestParams.type !== 'space') && (
                                <div className='flex flex-col items-center justify-center h-full gap-4 m-auto text-center text-textSecondary'>
                                    <GlobalViewEmptyMessage
                                        type={requestParams.type}
                                        dashCount={dashboardCount}
                                        monitorCount={monitorCount}
                                    />
                                </div>
                            )
                        }
                    />
                )}
                {isLoading && <LoadingSpinner />}
            </div>
        </div>
    );
}
