import { cn } from '@/lib/cn';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'components/tooltip/Tooltip';
import { memo } from 'react';
import { useLocation } from 'react-router';
import { Position, NodeToolbar as ReactFlowToolbar } from 'reactflow';
import { NavLink } from 'ui/nav/components/NavLink';
import { ExpandButton } from './ExpandButton';
import { FixButton } from './FixButton';
import { GroupNodeObjectSelector } from './GroupNodeObjectSelector';
import { PinButton } from './PinButton';

interface NodeToolbarProps {
    id: string;
    isVisible: boolean;
    isPinned?: boolean;
    isFixed?: boolean;
    isExpanded?: boolean;
    label?: string;
    url?: string;
    hiddenConnections?: number;
    isGroup?: boolean;
}

const toolbarButtonClassName =
    'hover:bg-secondaryButtonBackgroundHover focus-visible:bg-secondaryButtonBackgroundHover hover:text-tertiaryButton focus-visible:text-tertiaryButton text-secondaryButton hover:text-secondaryButton';

export const NodeToolbar: React.FC<NodeToolbarProps> = memo(
    ({ id, isVisible, isPinned, isFixed, isExpanded, isGroup, url, hiddenConnections }) => {
        const { pathname } = useLocation();

        return (
            <ReactFlowToolbar
                position={Position.Top}
                className='flex text-sm border divide-x rounded divide-dividerPrimary bg-tileBackground border-dividerPrimary'
                offset={5}
                isVisible={isVisible}
                style={{ zIndex: 100 }}
            >
                <ExpandButton
                    id={id}
                    isPinned={isPinned}
                    isExpanded={isExpanded}
                    hiddenConnections={hiddenConnections}
                    className={cn('p-xxxs', toolbarButtonClassName)}
                />

                {isGroup && (
                    <GroupNodeObjectSelector
                        id={id}
                        isPinned={isPinned}
                        className={cn('p-xxxs', toolbarButtonClassName)}
                    />
                )}

                <PinButton id={id} isPinned={isPinned} className={cn('p-xxxs', toolbarButtonClassName)} />

                <FixButton id={id} isFixed={isFixed} className={cn('p-xxxs', toolbarButtonClassName)} />

                {url && pathname !== url && (
                    <Tooltip title='Drilldown' className={toolbarButtonClassName}>
                        <NavLink
                            to={url}
                            className='inline-flex items-center justify-center h-full py-xxxs px-1.5'
                            onClick={(e) => e.stopPropagation()}
                        >
                            <FontAwesomeIcon icon={faArrowRight} fixedWidth />
                        </NavLink>
                    </Tooltip>
                )}
            </ReactFlowToolbar>
        );
    }
);
