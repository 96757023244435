import { FeatureKey, getFeatureDisplay, isFeatureEnabled } from '@squaredup/tenants';
import { VariantProps, cva } from 'class-variance-authority';
import { useTenant } from 'queries/hooks/useTenant';
import { useTier } from 'queries/hooks/useTier';
import { IsTenantAdmin } from 'services/AccessControlService';
import { FeatureUnavailableContent, NarrowFeatureUnavailableContent, useShowUpgradeModal } from './common';
import { UpgradeMessageContainer } from './UpgradeMessageContainer';

const variants = cva(undefined, {
    variants: {
        container: {
            modal: '',
            page: 'min-w-[400px] w-fit'
        },
        content: {
            narrow: '',
            normal: ''
        }
    },
    defaultVariants: {
        container: 'page',
        content: 'normal'
    }
});

export interface FeatureUnavailableBannerProps extends VariantProps<typeof variants> {
    featureKey: FeatureKey;
    summary?: string;
    title?: string;
    className?: string;
}

export const FeatureUnavailableBanner: React.FC<FeatureUnavailableBannerProps> = ({
    featureKey,
    className,
    summary,
    title,
    container,
    content
}) => {
    const { data: tenant } = useTenant();
    const { openUpgradeModal, upgradeModal } = useShowUpgradeModal(tenant);
    const canUpgrade = IsTenantAdmin(tenant) ?? false;
    // useTier handles validating the tier, and coercing items like dates from strings
    const { data: tier } = useTier();

    const feature = getFeatureDisplay(featureKey);
    if (!tier || feature === undefined) {
        return <></>;
    }

    const isEnabled = isFeatureEnabled(tier, featureKey);
    if (isEnabled) {
        return <></>;
    }

    const isUpgradePending = tenant?.licenceData?.upgradePending ?? false;

    return (
        <UpgradeMessageContainer
            data-testid='feature-unavailable-banner'
            className={variants({ container, className })}
            variant='normal'
        >
            {content === 'narrow' ? (
                <NarrowFeatureUnavailableContent
                    feature={feature}
                    canUpgrade={canUpgrade}
                    title={title}
                    summary={summary}
                    isUpgradePending={isUpgradePending}
                    onUpgradeClicked={openUpgradeModal}
                />
            ) : (
                <FeatureUnavailableContent
                    feature={feature}
                    canUpgrade={canUpgrade}
                    title={title}
                    summary={summary}
                    isUpgradePending={isUpgradePending}
                    onUpgradeClicked={openUpgradeModal}
                />
            )}
            {upgradeModal}
        </UpgradeMessageContainer>
    );
};
