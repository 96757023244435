import { Divider } from '@/components/Divider';
import { AccordionMultipleProps } from '@radix-ui/react-accordion';
import { StreamDataColumn } from '@squaredup/data-streams';
import Field from 'components/forms/field/Field';
import { defaultWrapperClassName } from 'components/forms/input/Input';
import { OptionDropdown } from 'components/visualisationOptions/OptionDropdown';
import { OptionToggle } from 'components/visualisationOptions/OptionToggle';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { VisualisationConfigAccordion } from '../VisualisationConfigAccordion';
import { DataStreamBarChartConfig, displayModeValues, layoutValues } from './Config';
import { FormDropdown } from './components/FormDropdown';
import { RangeControl } from './components/RangeControl';
import { hasSeriesColumn } from './dataUtils';

const legendPositionOptions = [
    { label: 'Bottom', value: 'bottom' },
    { label: 'Top', value: 'top' },
    { label: 'Left', value: 'left' },
    { label: 'Right', value: 'right' }
];

export const BarChartConfigurationForm = ({
    config,
    columns,
    accordionControls,
    onChange
}: {
    config: DataStreamBarChartConfig;
    columns: StreamDataColumn[];
    accordionControls: AccordionMultipleProps;
    onChange: (data: any) => void;
}) => {
    const defaultValues: DataStreamBarChartConfig = {
        horizontalLayout: config?.horizontalLayout ?? 'vertical',
        grouping: config?.grouping ?? false,
        showValue: config?.showValue ?? false,
        displayMode: config?.displayMode ?? 'actual',
        xAxisLabel: config?.xAxisLabel ?? '',
        yAxisLabel: config?.yAxisLabel ?? '',
        range: config?.range ?? { type: 'auto' },
        showGrid: config?.showGrid ?? true,
        showLegend: config?.showLegend ?? false,
        legendPosition: config?.legendPosition ?? 'bottom'
    };

    const formMethods = useForm<DataStreamBarChartConfig>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues
    });

    const { watch, reset, register, handleSubmit, setValue } = formMethods;
    useEffect(() => {
        // Cast required to get TS to allow validation before submit
        // Works fine but underlying types don't quite match
        const subscription = watch(handleSubmit(onChange) as any);
        return () => subscription.unsubscribe();
    }, [watch, onChange, handleSubmit]);

    useEffect(() => {
        if (isEmpty(config)) {
            reset(defaultValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChange, config, reset]);

    const grouping = watch('grouping');
    const showLegend = watch('showLegend');
    const displayMode = watch('displayMode');
    const orientationMode = watch('horizontalLayout');

    const xAxisGroup = config.xAxisGroup;

    useEffect(() => {
        if (xAxisGroup === 'none') {
            setValue('grouping', false);
            setValue('showLegend', false);
            setValue('displayMode', 'actual');
        }

        if (displayMode === 'percentage') {
            setValue('range', { type: 'auto' });
        }

        if (orientationMode === 'horizontal') {
            setValue('horizontalLayout', 'horizontal');
        } else {
            setValue('horizontalLayout', 'vertical');
        }

        if (grouping) {
            setValue('displayMode', 'actual');
        }
    }, [displayMode, grouping, orientationMode, xAxisGroup, setValue]);

    const isHorizontal = orientationMode === 'horizontal';

    // Has a series column been selected or would be auto-selected
    const isSeriesColumnPresent = hasSeriesColumn(columns, config);

    return (
        <FormProvider {...formMethods}>
            <form className='w-full'>
                <VisualisationConfigAccordion value='type' label='Type' accordionControls={accordionControls}>
                    <Controller
                        name='grouping'
                        render={({ field: { onChange: toggleChange, value, onBlur } }) => (
                            <OptionToggle
                                name='grouping'
                                label='Show as groups'
                                disabled={!isSeriesColumnPresent}
                                checked={value}
                                onChange={(data) => {
                                    toggleChange(data);
                                    onBlur();
                                }}
                            />
                        )}
                    />

                    <Field
                        label='Mode'
                        help='Percentage mode displays series as a percentage of the overall bar. Only available when a Series is selected.'
                        helpAlign='Left'
                        tooltipAlign='Right'
                    >
                        <FormDropdown
                            name='displayMode'
                            options={displayModeValues}
                            isDisabled={!isSeriesColumnPresent || grouping}
                        />
                    </Field>

                    <Field label='Layout'>
                        <FormDropdown name='horizontalLayout' options={layoutValues} />
                    </Field>
                </VisualisationConfigAccordion>

                <Divider />

                <VisualisationConfigAccordion value='xAxis' label='X-Axis' accordionControls={accordionControls}>
                    <Field label='Label'>
                        <input
                            type='text'
                            className={defaultWrapperClassName}
                            {...register(!isHorizontal ? 'xAxisLabel' : 'yAxisLabel')}
                            maxLength={250}
                            placeholder='Auto'
                        />
                    </Field>
                </VisualisationConfigAccordion>

                <Divider />

                <VisualisationConfigAccordion value='yAxis' label='Y-Axis' accordionControls={accordionControls}>
                    <Field label='Label'>
                        <input
                            type='text'
                            className={defaultWrapperClassName}
                            {...register(isHorizontal ? 'xAxisLabel' : 'yAxisLabel')}
                            maxLength={250}
                            defaultValue={config?.xAxisLabel}
                            placeholder='Auto'
                        />
                    </Field>

                    <Field label='Range'>
                        <RangeControl name='range' isDisabled={displayMode === 'percentage'} />
                    </Field>
                </VisualisationConfigAccordion>

                <Divider />

                <VisualisationConfigAccordion value='legend' label='Legend' accordionControls={accordionControls}>
                    <Controller
                        name='showLegend'
                        render={({ field: { onChange: toggleChange, value, onBlur } }) => (
                            <OptionToggle
                                name='showLegend'
                                label='Show legend'
                                checked={value}
                                disabled={!isSeriesColumnPresent}
                                wrapperClassName='mt-3'
                                onChange={(data) => {
                                    toggleChange(data);
                                    onBlur();
                                }}
                            />
                        )}
                    />

                    {showLegend && (
                        <div className='flex items-center mt-3 space-x-4'>
                            <label>Position</label>
                            <div className='flex-1'>
                                <Controller
                                    name='legendPosition'
                                    render={({ field: { onChange: toggleChange, value } }) => (
                                        <OptionDropdown
                                            options={legendPositionOptions}
                                            defaultValue={
                                                legendPositionOptions.find((option) => option.value === value) ??
                                                legendPositionOptions[0]
                                            }
                                            onChange={(d) => toggleChange(d.data)}
                                            action='bar-legend-position'
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    )}
                </VisualisationConfigAccordion>

                <Divider />

                <VisualisationConfigAccordion value='options' label='Options' accordionControls={accordionControls}>
                    <Controller
                        name='showGrid'
                        render={({ field: { onChange: toggleChange, value, onBlur } }) => (
                            <OptionToggle
                                name='showGrid'
                                label='Grid lines'
                                checked={value}
                                onChange={(data) => {
                                    toggleChange(data);
                                    onBlur();
                                }}
                            />
                        )}
                    />

                    <Controller
                        name='showValue'
                        render={({ field: { onChange: toggleChange, value, onBlur } }) => (
                            <OptionToggle
                                name='showValue'
                                label='Annotation'
                                checked={value}
                                help='Display an annotation showing the value for each bar. Annotation may be hidden based on available space.'
                                wrapperClassName='mt-3'
                                onChange={(data) => {
                                    toggleChange(data);
                                    onBlur();
                                }}
                            />
                        )}
                    />
                </VisualisationConfigAccordion>
            </form>
        </FormProvider>
    );
};
