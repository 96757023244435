import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/button/Button';
import { useDataStreamConfig } from 'dashboard-engine/hooks/useDataStreamConfig';
import { useFlag } from 'lib/useFlag';
import { useState, type FC } from 'react';
import { useQueryClient } from 'react-query';
import SQLEditor from 'ui/editor/components/SQLEditor';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { useDraftSQLContext } from '../../contexts/DraftSQLContext';
import { useTileEditorContext } from '../../contexts/TileEditorContext';
import { useIsFetchingSQLData } from '../hooks/useIsFetchingSQLData';

export const AnalyticsQueryEditor: FC = () => {
    const { tileConfig, setTileConfig } = useTileEditorContext();
    const { datasets, setActivePreviewTab } = useDatasetContext();
    const allowsNoCache = useFlag('noCacheSQL');

    const [newSQL, setNewSQL] = useState(tileConfig.dataStream?.dataSourceConfig?.sql || '');
    const { draftQuery, setDraftQuery } = useDraftSQLContext();
    const [draftTileConfig, setDraftTileConfig] = useState(tileConfig);
    const isFetching = useIsFetchingSQLData(newSQL);

    const queryClient = useQueryClient();
    const [lastTriggered, setLastTriggered] = useState<number>(0);
    const { data: previewData, queryKey: previewQueryKey } = useDataStreamConfig(tileConfig);
    const { data: cacheBustedData } = useDataStreamConfig(draftTileConfig, {
        dataStreamOptions: { noCacheRead: true },
        extraKeys: [lastTriggered]
    });

    const hasChangedSQL = tileConfig.dataStream?.dataSourceConfig?.sql !== newSQL;

    if (
        cacheBustedData.metadata.requestStats.completedAt > previewData.metadata.requestStats.completedAt &&
        previewQueryKey != null
    ) {
        queryClient.setQueryData(previewQueryKey, cacheBustedData);
    }

    const handleSQLUpdate = () => {
        if (newSQL) {
            setTileConfig({
                ...tileConfig,
                dataStream: {
                    ...tileConfig.dataStream,
                    dataSourceConfig: {
                        ...tileConfig.dataStream?.dataSourceConfig,
                        sql: draftQuery
                    }
                }
            });

            // Move to the data tab so the user can see the output
            setActivePreviewTab(datasets.length + 1);
        }
    };

    const handleNoCacheSQLUpdate = () => {
        if (newSQL) {
            // This updates the tile preview, but the config isn't updated until the tile is saved
            setDraftTileConfig({
                ...tileConfig,
                dataStream: {
                    ...tileConfig.dataStream,
                    dataSourceConfig: {
                        ...tileConfig.dataStream?.dataSourceConfig,
                        sql: draftQuery
                    }
                }
            });

            // Move to the data tab so the user can see the output
            setActivePreviewTab(datasets.length + 1);
        }
        // Force the cacheBustedData to update
        setLastTriggered(Date.now());
    };

    const storeSQL = (currentSQL: string) => {
        setDraftQuery(currentSQL);
    };

    return (
        <div className='flex flex-col flex-1 h-full min-w-0 pl-6 pr-5 space-y-4 py-7'>
            <div className='flex-1 min-h-0'>
                <SQLEditor
                    content={newSQL}
                    onValidUpdatedContent={(query) => {
                        setNewSQL(query || '');
                        storeSQL(query || '');
                    }}
                />
            </div>
            <div className='flex items-center flex-shrink-0 pl-4 pr-4 space'>
                <div className='inline-flex items-center mr-4 space-x-2 text-sm'>
                    <FontAwesomeIcon icon={faQuestionCircle} className='text-textSecondary' />
                    <p>
                        Table names, column names, and values are case sensitive and may need to be enclosed with{' '}
                        <code>[]</code>.{' '}
                        <a
                            className='text-textLink'
                            href='https://squaredup.com/cloud/analytics/'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Learn more.
                        </a>
                    </p>
                </div>

                <div className='flex gap-4 ml-auto w-fit'>
                    <Button
                        onClick={handleSQLUpdate}
                        className='inline-flex items-center ml-auto space-x-2'
                        disabled={isFetching || !hasChangedSQL}
                    >
                        {isFetching ? <LoadingSpinner size={18} /> : 'Execute'}
                    </Button>

                    {allowsNoCache && (
                        <Button
                            onClick={handleNoCacheSQLUpdate}
                            className='inline-flex items-center ml-auto space-x-2'
                            disabled={isFetching}
                        >
                            {isFetching ? <LoadingSpinner size={18} /> : 'Execute - Bypass Cache'}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};
