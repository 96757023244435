import Text from '@/components/Text';
import useSize from '@react-hook/size';
import { useVirtualizer } from '@tanstack/react-virtual';
import Button from 'components/button';
import { FeatureUnavailablePill } from 'components/plans/FeatureUnavailablePill';
import type { ViewOption } from 'dynamo-wrapper';
import { clamp, upperFirst } from 'lodash';
import { useDatasourceConfigs } from 'queries/hooks/useDatasourceConfigs';
import { useWorkspaces } from 'queries/hooks/useWorkspaces';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useBlockReason } from '../../utils/useBlockReason';
import { StatusRequestType } from '../StatusOverview';
import { StatusBlock, StatusBlockData, type StatusBlockDataItem } from './StatusBlock';

interface BlockWrapperProps {
    blockData: StatusBlockDataItem;
    extraData: StatusBlockData;
    showFooter?: boolean;
    type: StatusRequestType;
    viewOptions?: ViewOption[];
}

interface StatusBlocksProps {
    data: StatusBlockDataItem[];
    columns: number;
    fillGrid?: boolean;
    viewOptions?: ViewOption[];
    showAddWorkspace: boolean;
    noBlocksMessage?: React.ReactNode;
    onAddWorkspaceClick?: () => void;
    type: StatusRequestType;
    isWorkspaceFeatureEnabled: boolean;
}

const BlockWrapper: React.FC<BlockWrapperProps> = ({ blockData, extraData, showFooter, type, viewOptions }) => {
    const reason = useBlockReason(type, blockData.id, blockData.state);

    blockData.sublabel = reason;

    return (
        <StatusBlock blockData={blockData} extraData={extraData} showFooter={showFooter} viewOptions={viewOptions} />
    );
};

export const StatusBlocks: React.FC<StatusBlocksProps> = ({
    data,
    columns,
    showAddWorkspace,
    viewOptions,
    noBlocksMessage,
    type,
    onAddWorkspaceClick,
    isWorkspaceFeatureEnabled
}) => {
    const { data: workspaces } = useWorkspaces();
    const { data: plugins } = useDatasourceConfigs();

    const blocksDataIds = data.map((d) => d.id);

    const extraDataMap = useMemo(() => {
        const map = new Map();

        switch (type) {
            case 'space': {
                blocksDataIds?.forEach((workspaceId: any) => {
                    const workspace = workspaces?.find((w: any) => w.id === workspaceId);

                    if (workspace) {
                        map.set(workspace.id, {
                            type: upperFirst(workspace.data?.properties?.type ?? ''),
                            tags: workspace.data?.properties?.tags ?? [],
                            plugins: plugins
                                ?.filter((p) => (workspace.data?.links?.plugins ?? []).includes(p.id))
                                .map((p) => ({
                                    id: p.id,
                                    workspaceId: workspace.id,
                                    displayName: p.displayName,
                                    name: p.plugin?.name
                                }))
                        });
                    }
                });
                break;
            }
            default: {
                break;
            }
        }

        return map;
    }, [type, workspaces, plugins, blocksDataIds]);

    const parentRef = React.useRef<HTMLDivElement>(null);

    const [, height] = useSize(parentRef);

    const estimateSize = useCallback(
        () => clamp(height / Math.max(Math.floor(data.length / columns) + 1, 3), 185, 365),
        [columns, data.length, height]
    );

    const rowVirtualizer = useVirtualizer({
        count: data.length + 1,
        getScrollElement: () => parentRef.current,
        estimateSize,
        overscan: columns * 4,
        lanes: columns
    });

    useEffect(() => {
        rowVirtualizer.measure();
    }, [estimateSize, rowVirtualizer, columns, data.length, height]);

    return (
        <div
            ref={parentRef}
            className='overflow-auto h-full scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'
            role='grid'
            aria-colcount={columns}
        >
            {data.length === 0 && !showAddWorkspace && <>{noBlocksMessage}</>}
            {(data.length !== 0 || showAddWorkspace) && (
                <div
                    style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                        width: '100%',
                        position: 'relative'
                    }}
                >
                    {rowVirtualizer.getVirtualItems().map((virtualItem) => (
                        <div
                            key={virtualItem.key}
                            className='p-1'
                            role='gridcell'
                            style={{
                                position: 'absolute',
                                top: 0,
                                ...(virtualItem.index % (columns * 2) >= columns
                                    ? { right: `${virtualItem.lane * (100 / columns)}%` }
                                    : { left: `${virtualItem.lane * (100 / columns)}%` }),
                                width: `${100 / columns}%`,
                                height: `${virtualItem.size}px`,
                                transform: `translateY(${virtualItem.start}px)`
                            }}
                        >
                            {virtualItem.index === data.length ? (
                                <>
                                    {showAddWorkspace && (
                                        <div className='flex flex-col items-center justify-center w-full h-full p-2 border border-outlinePrimary'>
                                            {!isWorkspaceFeatureEnabled && (
                                                <div className='flex items-center mb-8'>
                                                    <Text.H2>Workspaces</Text.H2>
                                                    <FeatureUnavailablePill
                                                        featureKey='workspaces'
                                                        title='Organize your dashboards and data'
                                                        className='ml-3'
                                                    />
                                                </div>
                                            )}
                                            <Button
                                                onClick={onAddWorkspaceClick}
                                                className='w-full max-w-min'
                                                disabled={!isWorkspaceFeatureEnabled}
                                            >
                                                Add workspace
                                            </Button>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <BlockWrapper
                                    blockData={data[virtualItem.index]! as any}
                                    extraData={extraDataMap.get(data[virtualItem.index].id)}
                                    showFooter={Array.from(extraDataMap.values()).some((d) => d?.plugins?.length > 0)}
                                    type={type}
                                    viewOptions={viewOptions}
                                />
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
