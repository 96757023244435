import Text from '@/components/Text';
import Modal, { ModalButtons } from 'components/Modal';
import Button from 'components/button/Button';
import { MutableRefObject, ReactChild, useReducer, useRef } from 'react';
import Select from 'react-select';
import {
    ENTERPRISE_FAKE_ID,
    STARTER_MONTHLY_PADDLE_ID,
    planOptions,
    planRanges
} from './Plans';
import Config from 'config';
import Auth from 'services/Auth';
import { ProjectedTenantResponse, SetUpgradePending } from 'services/TenantService';
import { Tier } from '@squaredup/tenants';
import { cn } from '@/lib/cn';
import { useQueryClient } from 'react-query';
import { tenantQueryKeys } from 'queries/queryKeys/tenantKeys';

type FormFieldProps = {
    title: string;
    children: ReactChild;
    isDisabled?: boolean;
};

const FormField = ({ title, children, isDisabled = false }: FormFieldProps) => (
    <div className='mt-8 first:mt-0'>
        <Text.H3 className={cn('flex items-end mb-2', isDisabled && 'text-textDisabled')}>{title}</Text.H3>
        {children}
    </div>
);

type PlanUpgradePanelProps = {
    onClose: () => void;
    onSubmit: (plan: number, users: number) => void;
    organisation?: string;
    tier?: Tier;
};

type PlanUpgradeModalProps = {
    onClose: () => void;
    onPurchase?: () => void;
    tenant: ProjectedTenantResponse;
};

type ReducerState = {
    userCount: number;
    plan: number;
};

type ReducerAction = {
    type: string;
    value: number;
};

const checkUsersForPlan = (users: number, plan: number): number => {
    const { min, max } = planRanges[plan];
    return Math.max(Math.min(max, Math.floor(users ?? 0)), min);
};

type DescriptorBlockProps = {
    title: string;
    cost: string;
    description: JSX.Element;
};

const DescriptorBlock = ({
    title,
    cost,
    description
}: DescriptorBlockProps) => (
    <div className='flex flex-col content-center h-full gap-8 px-6 py-6 justify-normal text-primaryButtonText'>
        <div>
            <Text.H2>{title}</Text.H2>
        </div>
        <div className='pb-4'>
            <span className='text-3xl font-semibold'>{cost}</span>
            <span className='text-xl'> /user/mo</span><br/>
            <span className='text-sm'>when billed annually</span>
        </div>
        {description}
    </div>
);

const PlanImage = () => (
    <div className='grid h-full max-w-3xl grid-cols-3 mr-8'>
        <div className='bg-[#212030]'>
            <DescriptorBlock
                title='Starter'
                cost='$9'
                description={
                    <div className='text-sm opacity-75'>
                        <span>Everything from Free plus:</span><br/> 
                        <span>Up to 10 data sources</span><br/>
                        <span>Up to 30 monitors</span><br/>
                        <span>Online support</span>
                    </div>
                }
            />
        </div>
        <div className='bg-[#6a32f4]'>
            <DescriptorBlock
                title='Pro'
                cost='$35'
                description={
                    <div className='text-sm opacity-75'>
                        <span>Everything from Starter plus:</span><br/>
                        <span>Unlimited data sources</span><br/>
                        <span>Unlimited dashboard sharing</span><br/>
                        <span>Unlimited monitors</span><br/>
                        <span>Access control</span><br/>
                        <span>200,000 queries/month</span><br/>
                        <span>5,000 map objects</span><br/>
                        <span>Additional Pro features</span>
                    </div>
                }
            />
        </div>
        <div className='bg-[#481fa8]'>
            <DescriptorBlock
                title='Enterprise'
                cost='$78'
                description={
                    <div className='text-sm opacity-75'>
                        <span>Everything from Pro plus:</span><br/> 
                        <span>On-prem data sources</span><br/>
                        <span>Full API access</span><br/>
                        <span>1,000,000 queries/month</span><br/>
                        <span>10,000 map objects</span><br/>
                        <span>Volume discounts</span><br/>
                        <span>Dedicated support</span><br/>
                        <span>Additional Ent features</span>
                    </div>
                }
            />
        </div>
    </div>
);

export const PlanUpgradePanel = ({ onClose: close, onSubmit: submit, organisation, tier } : PlanUpgradePanelProps) => {
    let userCountRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

    const updateRef = (value: number) => {
        if (userCountRef.current) {
            userCountRef.current.value = value.toString();
        }
    };
    
    const reducer = (state: ReducerState, action: ReducerAction) => {
        switch (action.type) {
            case 'setUserCount': {
                const actionCount = isNaN(action.value) ? 0 : action.value;
                const userCount = checkUsersForPlan(actionCount, state.plan);
                return {
                    ...state,
                    userCount
                };
            }
            case 'setPlan': {
                const userCount = planRanges[action.value].min;
                updateRef(userCount);
                return {
                    userCount,
                    plan: action.value
                };
            }
            default: {
                throw new Error(`Unknown action: '${action.type}'`);
            }
        }
    };

    let [state, dispatch] = useReducer(reducer, {
        plan: STARTER_MONTHLY_PADDLE_ID,
        userCount: planRanges[STARTER_MONTHLY_PADDLE_ID].min
    });

    const isOnExistingPlan = tier?.expiry !== undefined;
    const isUpgradeBlocked = state.plan === ENTERPRISE_FAKE_ID || isOnExistingPlan ;

    return (
        <>
            <div className='flex gap-16 mx-8'>
                <div>
                    <Text.Body>
                        For full details about our plans,{' '}
                        <Button type='button' variant='link' href='http://squaredup.io/pricing'>
                            click here
                        </Button>
                        .
                    </Text.Body>
                    <FormField title='Plan' isDisabled={isOnExistingPlan}>
                        <div className='ring-inset ring-1 ring-outlinePrimary focus-within:ring-outlineSecondary rounded-input bg-componentBackgroundPrimary'>
                            <Select
                                name='plan'
                                isDisabled={isOnExistingPlan}
                                classNamePrefix='autocomplete'
                                onChange={(s) => dispatch({ type: 'setPlan', value: s?.value ?? 0 })}
                                options={planOptions}
                                defaultValue={planOptions[0]}
                            />
                        </div>
                    </FormField>

                    <div
                        className={cn('mt-8', isUpgradeBlocked && 'hidden')}
                        data-testid='user-container'
                    >
                        <FormField title='Number of users'>
                            <input
                                className='border-none ring-inset ring-1 ring-outlinePrimary text-textPrimary rounded-input leading-input focus:ring-0 focus:outline-none focus-visible:ring-inset focus-visible:ring-1 py-input px-md bg-componentBackgroundPrimary focus:ring-outlineSecondary hover:ring-outlineSecondary'
                                type='number'
                                name='userCount'
                                ref={userCountRef}
                                min={planRanges[state.plan]?.min.toString() ?? 0}
                                max={planRanges[state.plan]?.max.toString() ?? 0}
                                step={1}
                                onBlur={() => {
                                    updateRef(state.userCount ?? checkUsersForPlan(0, state.plan));
                                }}
                                onChange={(e) =>
                                    dispatch({
                                        type: 'setUserCount',
                                        value: e.target.valueAsNumber
                                    })
                                }
                                defaultValue={planRanges[STARTER_MONTHLY_PADDLE_ID].min}
                            />
                        </FormField>

                        <FormField title='Price'>
                            <Text.Body className='text-textSecondary'>Price will be calculated at checkout.</Text.Body>
                        </FormField>
                    </div>
                </div>
                <PlanImage />
            </div>

            <ModalButtons>
                <Button type='button' variant='tertiary' onClick={() => close()} data-testid='upgrade-modal-cancel'>
                    Cancel
                </Button>
                {isUpgradeBlocked ? (
                    <a
                        href={`mailto:sales@squaredup.com?subject=Service%20Request&body=%0D%0A%0D%0A%E2%80%94-%0D%0AOrganisation%3A%20${
                            organisation ?? ''
                        }`}
                    >
                        <Button type='button' data-testid='upgrade-modal-contact'>Contact sales</Button>
                    </a>
                ) : (
                    <Button
                        type='button'
                        onClick={() => submit(state.plan, state.userCount)}
                        data-testid='upgrade-modal-purchase'
                    >
                        Purchase
                    </Button>
                )}
            </ModalButtons>
        </>
    );

};

export const PlanUpgradeModal = ({ onClose: close, onPurchase, tenant }: PlanUpgradeModalProps) => {

    const queryClient = useQueryClient();
    const onSuccessfulPurchase = () => {
        // Set update pending on local copy (in-case websocket notification doesn't update)
        queryClient.setQueryData(tenantQueryKeys.tenant, {
            ...tenant,
            licenceData: {
                ...tenant.licenceData,
                upgradePending: true
            }
        });

        // Update server
        SetUpgradePending();
        
        if (onPurchase && typeof onPurchase === 'function') {
            onPurchase();
        }
    };

    const submitUpgradeRequest = (planId: number, users: number) => {
        Paddle.Checkout.open({
            product: planId,
            email: Auth.user?.name || '',
            quantity: users,
            allowQuantity: false,
            disableLogout: true,
            successCallback: onSuccessfulPurchase,
            passthrough: `{"tenantId": "${tenant.id}", "environment": "${Config.Environment}" }`
        });
    };

    return (
        <Modal title='Select an upgrade option' close={close}>
            <PlanUpgradePanel
                onClose={close}
                onSubmit={submitUpgradeRequest}
                organisation={tenant.displayName}
                tier={tenant.tier}
            />
        </Modal>
    );
};
