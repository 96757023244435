import Text from '@/components/Text';
import { faSignOut } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TimeframeEnumValue, defaultTimeframeEnum } from '@squaredup/timeframes';
import { AppLoadingSpinner } from 'components/AppLoadingSpinner';
import Button from 'components/button';
import { AppContext } from 'contexts/AppContext';
import DashboardContext from 'contexts/DashboardContext';
import { FullScreenWrapper } from 'dashboard-engine/fullscreen/FullScreenWrapper';
import RenderDynamic from 'dashboard-engine/render/RenderDynamic';
import { DashboardVariable } from 'dashboard-engine/types/Dashboard';
import { checkIfAllTilesUseFixedTimeframe } from 'dashboard-engine/util/dashboard';
import type { OOBInfo } from 'dynamo-wrapper';
import { motion } from 'framer-motion';
import { usePageTitle } from 'lib/usePageTitle';
import { DashboardVariablesControl } from 'pages/dashboard/components/DashboardVariablesControl';
import { useInitialVariableSelection } from 'pages/dashboard/components/utils/useInitialVariableSelection';
import { setVariableParamsOnUrlSearchParams } from 'pages/dashboard/components/utils/useSetVariableSearchParams';
import { DashboardMetricsProvider } from 'pages/dashboard/DashboardMetrics';
import { useDashboard } from 'queries/hooks/useDashboard';
import { useDashboardVariables } from 'queries/hooks/useDashboardVariables';
import { useContext, useEffect, useState } from 'react';
import { DashboardOptions } from 'ui/dashboardOptions/DashboardOptions';
import { useDashboardId } from 'ui/hooks/useDashboardId';
import TimeframeChooser from 'ui/timeframe/TimeframeChooser';

export const OpenAccessDashboard: React.FC = () => {
    const id = useDashboardId();

    const [isNarrowLayout, setIsNarrowLayout] = useState(false);
    const [timeframe, setTimeframe] = useState<TimeframeEnumValue>(defaultTimeframeEnum);
    const [variables, setVariables] = useState<DashboardVariable[]>([]);

    const { setCurrentWorkspaceID } = useContext(AppContext);

    const initialNodes = useInitialVariableSelection(id);

    const { data: dashboard, isLoading: isLoadingDashboard } = useDashboard(id);
    const { data: dashboardVariables, isLoading: isLoadingVariables } = useDashboardVariables(id, initialNodes);

    const isLoading = isLoadingDashboard || isLoadingVariables;

    const allTilesUseFixedTimeframe = checkIfAllTilesUseFixedTimeframe(dashboard?.content?.contents);

    useEffect(() => {
        setCurrentWorkspaceID({
            id: dashboard?.workspaceId,
            force: true
        });
    }, [dashboard?.workspaceId, setCurrentWorkspaceID]);

    // Ensure timeframe reflects dashboard timeframe (if set)
    useEffect(() => {
        setTimeframe(dashboard?.timeframe || defaultTimeframeEnum);
    }, [dashboard?.timeframe]);

    useEffect(() => {
        setVariables(dashboardVariables || []);
    }, [dashboardVariables]);

    // Update page title as dashboard display name becomes available
    usePageTitle(dashboard?.displayName);

    const getOpenInDashboardQueryString = () => {
        const urlParams = new URLSearchParams();
        setVariableParamsOnUrlSearchParams(urlParams, variables);
        return urlParams.toString();
    };

    if (isLoading) {
        return <AppLoadingSpinner message={'Loading...'} />;
    }

    return (
        // Note: The id dashDownloadAsImageContainer is used to generate images from dashboards - don't change!
        <div
            id='dashDownloadAsImageContainer'
            className='flex flex-col w-full h-full min-h-0 px-4 pt-4 pb-16 overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'
        >
            <DashboardMetricsProvider
                key={dashboard?.id}
                dashboardContents={dashboard?.content?.contents ?? []}
                oobInfo={dashboard?.oobInfo as OOBInfo}
            >
                <DashboardContext.Provider
                    value={{
                        editing: false,
                        showDetailedErrors: false,
                        isNarrowLayout,
                        setIsNarrowLayout,
                        dashboard: dashboard!,
                        timeframe,
                        variables,
                        setVariables
                    }}
                >
                    <motion.div
                        initial={{
                            opacity: 0,
                            y: 192
                        }}
                        transition={{
                            ease: 'easeOut',
                            duration: 0.5
                        }}
                        animate={{
                            opacity: 1,
                            y: 0
                        }}
                    >
                        <div className='flex items-center mb-2' data-testid='header'>
                            {isNarrowLayout ? (
                                <Text.H2 className='h-full truncate'>{dashboard?.displayName}</Text.H2>
                            ) : (
                                <Text.H1 className='h-full truncate'>{dashboard?.displayName}</Text.H1>
                            )}

                            <DashboardVariablesControl canEdit={false} />

                            <div className='flex items-center pl-2 ml-auto space-x-4' data-testid='actions'>
                                {!allTilesUseFixedTimeframe && (
                                    <TimeframeChooser
                                        disabled={true}
                                        currentTimeframe={timeframe}
                                        onChange={(newTimeframe: TimeframeEnumValue) => setTimeframe(newTimeframe)}
                                    />
                                )}
                                <Button
                                    variant='secondary'
                                    icon={<FontAwesomeIcon icon={faSignOut} />}
                                    href={`/dashboard/${id}?${getOpenInDashboardQueryString()}`}
                                >
                                    Open in app
                                </Button>
                                <div className='collapse-for-image-export'>
                                    <DashboardOptions
                                        isDashboardReadOnly={false}
                                        isDashboardOpenAccess={true}
                                        dashboard={dashboard}
                                        workspaceId={dashboard?.workspaceId!}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='mt-2'>
                            <FullScreenWrapper isEditingEnabled={false}>
                                <RenderDynamic config={dashboard?.content} key={dashboard?.id} />
                            </FullScreenWrapper>
                        </div>
                    </motion.div>
                </DashboardContext.Provider>
            </DashboardMetricsProvider>
        </div>
    );
};
