import { Node } from '@squaredup/graph';
import { createContext, useContext } from 'react';
import { InfiniteQueryObserverResult } from 'react-query';
import { useDataStreamObjectFilters } from '../TileEditor/hooks/useDataStreamObjectFilters';

export const tileEditorObjectsFilterDefaultValue = {
    isConfigured: false,
    objects: [],
    count: 0,
    selectedObjectsCount: 0,
    dynamicObjectsCount: 0,
    pluginLookup: new Map(),
    isFetchingObjects: false,
    isLoadingObjects: false,
    isLoadingFilters: false,
    isLoadingProperties: false,
    isLoadingPropertyValues: false,
    isFiltered: false,
    hasNextObjectsPage: false,
    isFetchingNextObjectsPage: false,
    dataStreamQuery: '',
    scopes: [],
    types: [],
    sources: [],
    properties: [],
    propertyValues: new Map(),
    isDynamic: false,
    selectedObjects: [],
    nonScopefiltersApplied: false,
    selectedAllObjects: false,
    filtersDisabled: false,
    scopesDisabled: false,
    dynamicToggleDisabled: false,
    filterQuery: '',
    filterScope: '',
    filterProperties: {},
    filterSources: [],
    filterTypes: [],
    interactedObjects: [],
    hasVariables: false,
    setSelectedObjects: () => undefined,
    setIsDynamic: () => undefined,
    setFilterQuery: () => undefined,
    setFilterScope: () => undefined,
    setFilterProperties: () => undefined,
    setFilterSources: () => undefined,
    setFilterTypes: () => undefined,
    handleSaveScope: () => undefined,
    handleDynamicScope: () => undefined,
    handleFixedScope: () => undefined,
    handleSetFilterState: () => undefined,
    setInteractedObjects: () => undefined,
    resetFilters: () => undefined,
    fetchNextObjectsPage: async () =>
        ({}) as InfiniteQueryObserverResult<{ objects: Node[]; count: number; nextPage: number }, unknown>
};

const TileEditorObjectsFilterContext = createContext<ReturnType<typeof useDataStreamObjectFilters>>(
    tileEditorObjectsFilterDefaultValue
);

TileEditorObjectsFilterContext.displayName = 'TileEditorObjectsFilterContext';

export const useTileEditorObjectsFilterContext = () => useContext(TileEditorObjectsFilterContext);

export default TileEditorObjectsFilterContext;
