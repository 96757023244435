import { Dialog, DialogContent } from '@/components/Dialog';
import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { ResizablePanelResizeHandle } from 'components/ResizablePanelResizeHandle';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useTileContext } from 'contexts/TileContext';
import { ClientDataStreamsContextProvider } from 'dashboard-engine/dataStreams/clientDataStreams/ClientDataStreamsContext';
import { prefetchMonitorCount } from 'queries/hooks/useMonitorsCount';
import { dashboardQueryKeys } from 'queries/queryKeys/dashboardKeys';
import { TileState } from 'queries/types/types';
import { ComponentRef, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Panel, PanelGroup } from 'react-resizable-panels';
import DatasetContext from '../contexts/DatasetContext';
import { DraftSQLContextProvider } from '../contexts/DraftSQLContext';
import TileEditorContext, { useTileEditorContext } from '../contexts/TileEditorContext';
import { DatasetEditor } from '../datasets/DatasetEditor';
import { DataStreamTileEditorHeader } from './DataStreamTileEditorHeader';
import { DataStreamTileEditorPreview } from './DataStreamTileEditorPreview';
import { DataStreamTileEditorVisualizationPanelWrapper } from './DataStreamTileEditorVisualizationPanelWrapper';
import { useDataStreamType } from './hooks/useDataStreamType';
import { useDatasets } from './hooks/useDatasets';
import { useMigratedTileConfigState } from './hooks/useDefaultScopeMigration';
import { TileEditorStateProvider } from './state/TileEditorStateProvider';
import { DataStreamTileEditorSteps } from './steps/DataStreamTileEditorSteps';

export const vizConfigPanelWidthPixels = 336;
export const editorStepsHeightPercentage = 60;

/**
 * @returns Styled wrapper component for ~StepSelector, ~DataStreamSelector, ~ObjectsSelector, ~Preview, ~Visualization
 */
export const DataStreamTileEditorWrapper = () => {
    const { isDatasetMode } = useTileEditorContext();
    const datasetState = useDatasets();

    const horizontalPanelGroupRef = useRef<ComponentRef<typeof PanelGroup>>(null);
    const verticalPanelGroupRef = useRef<ComponentRef<typeof PanelGroup>>(null);

    const handleResetHorizontalPanelGroupLayout = () => {
        if (!horizontalPanelGroupRef.current) {
            return;
        }
        const totalSize = horizontalPanelGroupRef.current.getLayout().reduce((acc, cur) => acc + cur.sizePixels, 0);
        horizontalPanelGroupRef.current.setLayout([
            { sizePixels: totalSize - vizConfigPanelWidthPixels },
            { sizePixels: vizConfigPanelWidthPixels }
        ]);
    };

    const handleResetVerticalPanelGroupLayout = () => {
        verticalPanelGroupRef.current?.setLayout([
            { sizePercentage: editorStepsHeightPercentage },
            { sizePercentage: 100 - editorStepsHeightPercentage }
        ]);
    };

    return (
        <div className='h-full p-4 overflow-hidden'>
            <DatasetContext.Provider value={datasetState}>
                <TileEditorStateProvider>
                    <PanelGroup ref={horizontalPanelGroupRef} direction='horizontal'>
                        <Panel>
                            <PanelGroup ref={verticalPanelGroupRef} direction='vertical'>
                                <Panel
                                    collapsible={true}
                                    minSizePixels={100}
                                    defaultSizePercentage={editorStepsHeightPercentage}
                                    className='bg-tileBackground text-textSecondary'
                                >
                                    {isDatasetMode ? <DatasetEditor /> : <DataStreamTileEditorSteps />}
                                </Panel>
                                <ResizablePanelResizeHandle
                                    direction='vertical'
                                    onResetLayout={handleResetVerticalPanelGroupLayout}
                                />
                                <Panel collapsible={true} minSizePixels={100}>
                                    <DataStreamTileEditorPreview />
                                </Panel>
                            </PanelGroup>
                        </Panel>
                        <ResizablePanelResizeHandle
                            direction='horizontal'
                            onResetLayout={handleResetHorizontalPanelGroupLayout}
                        />
                        <Panel
                            collapsible={true}
                            minSizePixels={200}
                            maxSizePercentage={50}
                            defaultSizePixels={vizConfigPanelWidthPixels}
                        >
                            <DataStreamTileEditorVisualizationPanelWrapper />
                        </Panel>
                    </PanelGroup>
                </TileEditorStateProvider>
            </DatasetContext.Provider>
        </div>
    );
};

interface DataStreamTileEditorProps {
    config: DataStreamBaseTileConfig;
    onClose: () => void;
}

/**
 *
 * @param param0 onClose: enables closing of the tile editor modal
 * @param param1 config: current tile config
 * @returns Root component for the new Tile Editor
 */
export const DataStreamTileEditor: React.FC<DataStreamTileEditorProps> = ({ onClose, config: savedTileConfig }) => {
    const queryClient = useQueryClient();

    const { tileId } = useTileContext();
    const { dashboard } = useDashboardContext();

    const { tileConfig, setTileConfig, isMigrating } = useMigratedTileConfigState(savedTileConfig);
    const [containsError, setContainsError] = useState(false);
    const [previewHealth, setPreviewHealth] = useState<TileState | undefined>(() => {
        if (!tileId) {
            return;
        }

        return queryClient.getQueryData<any>(dashboardQueryKeys.stateRollup(dashboard.id))?.tileStates?.[tileId];
    });

    const { dataStreamType, isLoading } = useDataStreamType(savedTileConfig);

    const isDatasetMode = tileConfig?.dataStream?.id === 'datastream-sql';

    // Prefetch monitor count so monitoring tab is in correct state when rendered for the first time.
    // Prefetching does not trigger a re-render when the value changes, or a network request if already cached.
    prefetchMonitorCount(queryClient);

    return (
        <>
            {!isLoading && !isMigrating && (
                <Dialog open={true}>
                    <DialogContent className='fixed inset-0 flex flex-col w-screen h-full max-h-full outline-none pointer-events-auto bg-backgroundPrimary animate-enter text-textPrimary'>
                        <TileEditorContext.Provider
                            value={{
                                inEditor: true,
                                tileConfig,
                                isDatasetMode,
                                savedTileConfig,
                                savedTileDataStreamType: dataStreamType,
                                containsError,
                                previewHealth,
                                setPreviewHealth,
                                setTileConfig,
                                setContainsError,
                                onClose
                            }}
                        >
                            <DraftSQLContextProvider
                                initialQuery={savedTileConfig.dataStream?.dataSourceConfig?.sql ?? ''}
                            >
                                <ClientDataStreamsContextProvider isClientDataStreamsEnabled={true}>
                                    <DataStreamTileEditorHeader />
                                    <DataStreamTileEditorWrapper key={isDatasetMode ? 'analyticsMode' : 'basicMode'} />
                                </ClientDataStreamsContextProvider>
                            </DraftSQLContextProvider>
                        </TileEditorContext.Provider>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};
