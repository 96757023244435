import { cn } from '@/lib/cn';
import {
    DataStreamScalarConfig,
    FormattedStreamValue,
    getScalarFormattedValue,
    isNoData
} from '@squaredup/data-streams';
import { HealthState } from '@squaredup/monitoring';
import { TruncatedText } from 'components/TruncatedText';
import { healthStateTextColors } from 'constants/state';
import { useFitTextWithBuffer } from 'lib/useFitTextWithBuffer';
import { useMemo, useState } from 'react';
import { DataStreamVisualisation } from '../../types/Visualisation';

type ScalarDisplayProps = {
    text: string;
    label?: string;
    labelPosition?: DataStreamScalarConfig['labelPosition'];
    health?: HealthState;
    manualSize?: number;
};

const ScalarDisplay = ({ text, label, labelPosition, health, manualSize }: ScalarDisplayProps) => {
    const [visible, setVisible] = useState(false);

    // Use max font size 2000 for a maximum of 20x (2000%) scaling
    const { fontSize, ref } = useFitTextWithBuffer({ maxFontSize: 2000, onFinish: () => setVisible(true) });
    const position = labelPosition || 'bottom';

    return (
        <div
            style={manualSize ? { fontSize: `${manualSize}px` } : { fontSize }}
            className={cn(
                'absolute grid font-semibold invisible text-center inset-[5%] font-inter bg-clip-text place-items-center overflow-hidden',
                typeof health === 'string' && healthStateTextColors[health],
                visible && 'visible'
            )}
        >
            <div className='w-full h-full min-w-0 min-h-0 whitespace-nowrap'>
                <div
                    className={cn('w-full h-full flex justify-center items-center', {
                        'flex-row gap-[5%]': position === 'right',
                        'flex-row-reverse gap-[5%]': position === 'left',
                        'flex-col': position === 'bottom',
                        'flex-col-reverse': position === 'top'
                    })}
                >
                    <div
                        ref={ref}
                        className={cn({
                            'w-full min-w-0': !manualSize,
                            'min-h-0': !manualSize && (position === 'top' || position === 'bottom'),
                            'flex-1': !manualSize && (position === 'left' || position === 'right')
                        })}
                    >
                        {text}
                    </div>

                    {label && (
                        <div
                            style={{ fontSize: 'max(25%, 12.5px)' }}
                            className={cn({
                                'min-w-0': !manualSize,
                                'w-full': !manualSize && (position === 'top' || position === 'bottom'),
                                'max-w-[50%]': position === 'left' || position === 'right',
                                'max-w-full': position === 'top' || position === 'bottom'
                            })}
                        >
                            <TruncatedText
                                className='min-w-0 font-sans font-semibold truncate text-textSecondary'
                                title={label}
                            >
                                {label}
                            </TruncatedText>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const getDisplayableValue = (value: FormattedStreamValue, formatted: boolean): string | null => {
    return formatted ? value.formatted : value.raw?.toLocaleString() ?? null;
};

const DataStreamScalar: DataStreamVisualisation<DataStreamScalarConfig> = ({ data, config, healthState }) => {
    const value = useMemo(() => {
        if (isNoData(data)) {
            return null;
        }

        const { value: scalarValue } = getScalarFormattedValue(data, config?.value);
        const formatted = config.formatted ?? true;

        if (!scalarValue) {
            return null;
        }

        if (Array.isArray(scalarValue)) {
            return getDisplayableValue(
                getScalarFormattedValue(data, { type: 'count' as const }).value as FormattedStreamValue,
                formatted
            );
        }

        return getDisplayableValue(scalarValue, formatted);
    }, [config, data]);

    return (
        <div className='w-full h-full leading-tight' data-visualization='data-stream-scalar'>
            <ScalarDisplay
                text={value ?? '0'}
                label={config?.label}
                labelPosition={config?.labelPosition}
                health={healthState}
                manualSize={config?.manualSize}
            />
        </div>
    );
};

DataStreamScalar.config = 'DataStreamScalarConfig';

export default DataStreamScalar;
